import React from "react";
import { LanguageContext } from "../context";
import { Container, Row, Col } from "react-bootstrap";
import Navigation from "../components/layout/Navigation";
import Logo from "../img/bageri Å_logo_2019.png";

const Landingpage = () => {
  return (
    <LanguageContext.Consumer>
      {context => (
        <Container fluid id="landingPageContainer">
          <Navigation context={context} />

          <Container id="landingPageContainer2">
            <Row className="justify-content-center">
              <Col className="text-center p-5">
                <p id="landingPageSubheader">
                  {context.state.language.landingpage.companyDesc}
                </p>
                <img src={Logo} id="landingPageLogo" />
                <p id="landingPageSubheader">IN THE HEART OF TURKU, KAKOLA</p>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </LanguageContext.Consumer>
  );
};

export default Landingpage;
