import React from "react";
import { Container, Carousel } from "react-bootstrap";
import kuva1 from "../img/Kakolapage1.jpg";
import kuva2 from "../img/Kakolapage2.jpg";
import kuva3 from "../img/Kakolapage3.jpg";
import kuva4 from "../img/Kakolapage4.jpg";
import kuva5 from "../img/Kakolapage5.jpg";

const Kakolapage = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center p-0"
      id="kakolaPageContainer"
    >
      <Carousel
        interval={2800}
        pauseOnHover={false}
        controls={false}
        indicators={false}
      >
        <Carousel.Item>
          <img className="kakolaPageImage" src={kuva1} alt="First slide" />
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img className="kakolaPageImage" src={kuva2} alt="Second slide" />

          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img className="kakolaPageImage" src={kuva3} alt="Third slide" />

          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img className="kakolaPageImage" src={kuva4} alt="Third slide" />

          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img className="kakolaPageImage" src={kuva5} alt="Third slide" />

          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};
export default Kakolapage;
