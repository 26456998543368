import React, { Component } from "react";
import "../../App.css";
import { Form, Nav } from "react-bootstrap";

class Navigation extends Component {
  render() {
    const { context } = this.props;

    return (
      <Nav
        className="justify-content-end"
        style={{ background: "transparent" }}
      >
        <Nav.Item>
          <Form.Control
            id="languageSelector"
            as="select"
            value={context.state.selectedLanguage}
            onChange={context.changeLanguage}
            context={context}
            style={{ fontSize: "1.3rem" }}
          >
            <option value={"fi"}>FI</option>
            <option value={"en"}>EN</option>
            <option value={"swe"}>SWE</option>
          </Form.Control>
        </Nav.Item>
      </Nav>
    );
  }
}

export default Navigation;
