import React from "react";
import "../App.css";
import { LanguageContext } from "../context";
import { Container, Image, Row, Col } from "react-bootstrap";
import kuva1 from "../img/AboutpageImage1.png";
import Logo from "../img/bageri Å_logo_2019Musta.png";

const Aboutpage = () => {
  return (
    <LanguageContext.Consumer>
      {context => (
        <Container fluid className="text-center" id="aboutPageContainer">
          <Row className="justify-content-center">
            <Image src={kuva1} id="aboutPageImage" />
          </Row>
          <Row className="justify-content-center">
            <Col sm={8}>
              <img src={Logo} id="aboutPageLogo" />

              <p className="aboutPageParagraph">
                {context.state.language.aboutpage.text1}
              </p>
              <p className="aboutPageParagraph">
                {context.state.language.aboutpage.text2}
              </p>
              <p className="aboutPageParagraph">
                {context.state.language.aboutpage.text3}
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </LanguageContext.Consumer>
  );
};

export default Aboutpage;
