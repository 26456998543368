import React from "react";
import Ticker from "react-ticker";
import "../../App.css";
import { LanguageContext } from "../../context";

const OpeningMarquee = () => {
  return (
    <div>
      <LanguageContext.Consumer>
        {context => (
          <Ticker speed={9}>
            {() => (
              <p className="WIPtickerText" style={{ margin: "0 8px" }}>
                {/* {context.state.language.openingTicker.tickerText} */}
                WORK IN PROGRESS
              </p>
            )}
          </Ticker>
        )}
      </LanguageContext.Consumer>
    </div>
  );
};

export default OpeningMarquee;
