import React, { Component } from "react";
import "./App.css";
import { LanguageProvider } from "./context";
import Landingpage from "./components/Landingpage";
import Aboutpage from "./components/Aboutpage";
import Kakolapage from "./components/Kakolapage";
import Socialpage from "./components/Socialpage";
import Footerpage from "./components/Footerpage";
import OpeningTicker from "./components/layout/OpeningTicker";
import WIPicker from "./components/layout/WIPTicker";
// import Textpage from "./components/Textpage";

class App extends Component {
  render() {
    return (
      <LanguageProvider>
        <React.Fragment>
          <Landingpage />
          <OpeningTicker />
          <Aboutpage />
          <WIPicker />
          <Kakolapage />
          <Socialpage />
          <Footerpage />
        </React.Fragment>
      </LanguageProvider>
    );
  }
}

export default App;
