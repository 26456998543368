import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import Logo from "../img/bageri Å_logo_2019.png";

const Socialpage = () => {
  return (
    <div>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center"
        id="socialPageContainer"
      >
        <Row className="justify-content-center">
          <Col className="text-center">
            <img src={Logo} id="socialPageLogo" />

            <br />
            <a href="https://www.instagram.com/bageri_o/" className="link">
              <FaArrowRight /> INSTAGRAM
            </a>
            <br />

            <a
              href="https://www.facebook.com/bageri-%C3%85-199627294253813/"
              className="link"
            >
              <FaArrowRight /> FACEBOOK
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Socialpage;
