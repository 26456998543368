import React, { Component } from "react";
import fi from "./components/languages/fi.json";
import en from "./components/languages/en.json";
import swe from "./components/languages/swe.json";

export const LanguageContext = React.createContext();

export class LanguageProvider extends Component {
  state = {
    language: fi,
    selectedLanguage: "fi"
  };

  changeLanguage = e => {
    let selectedLanguage = e.target.value;
    let language;

    if (selectedLanguage === "fi") {
      language = fi;
    } else if (selectedLanguage === "en") {
      language = en;
    } else if (selectedLanguage === "swe") {
      language = swe;
    } else {
      language = fi;
    }
    this.setState({
      language: language,
      selectedLanguage: selectedLanguage
    });
    console.log(selectedLanguage);
  };

  render() {
    return (
      <LanguageContext.Provider
        value={{ state: this.state, changeLanguage: this.changeLanguage }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}
