import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footerpage = () => {
  return (
    <div>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center"
        id="socialPageContainer2"
      >
        <Row className="justify-content-center">
          <Col className="text-center">
            <a href="mailto:info@bagerio.fi" className="link2">
              info@bagerio.fi
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footerpage;
