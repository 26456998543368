import React from "react";
import Ticker from "react-ticker";
import { LanguageContext } from "../../context";
import { Col } from "react-bootstrap";

const OpeningMarquee = () => {
  return (
    <div>
      <LanguageContext.Consumer>
        {context => (
          <Col className="p-0">
            <Ticker
              direction="toLeft"
              speed={10}
              offset="100%"
              mode="chain"
              move={true}
            >
              {() => (
                <p
                  className="tickerText"
                  style={{
                    margin: "0 10px",
                    color: "#00000",
                    padding: "0 10px",
                    whiteSpace: "nowrap"
                  }}
                >
                  OPENING IN 22.11.
                </p>
              )}
            </Ticker>
          </Col>
        )}
      </LanguageContext.Consumer>
    </div>
  );
};

export default OpeningMarquee;
